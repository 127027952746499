<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    @if (currentBuildingBillInfo !== undefined && building !== undefined) {
    <div class="sectiontitleicon">
        <img src="/assets/billing.png" />
        <div>
            <h2 class="tabtitle">
                Bollettazione <span class="titlemark">{{ building?.name }}</span>
            </h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>
    }
    @if (currentBuildingBillInfo) {
    <div class="tabcontainer__form">
        <div class="tabcontainer__form__inner">
            <mat-form-field>
                <mat-label>Data Bollettazione</mat-label>
                <!-- #docregion toggle -->
                <input matInput [matDatepicker]="picker" [disabled]="!this.currentBilling.draft"
                    [(ngModel)]="currentBuildingBillInfo.timestamp" />
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <!-- #enddocregion toggle -->
            </mat-form-field>

            <mat-form-field>
                <mat-label>Elementi da cacolare</mat-label>
                <mat-select [(ngModel)]="currentBuildingBillInfo.billComponents">
                    <mat-option [value]="0">Solo letture</mat-option>
                    <mat-option [value]="1">Letture e costi</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Stato</mat-label>
                <mat-select [(ngModel)]="this.currentBilling.draft" (selectionChange)="changeStatus()">
                    <mat-option [value]="true">Aperta</mat-option>
                    <mat-option [value]="false">Chiusa</mat-option>
                </mat-select>
            </mat-form-field>
            @if (this.currentBuildingBillInfo.filter) {
            <hr />
            <section>
                <mat-button-toggle-group multiple>
                    <mat-button-toggle [checked]="this.currentBuildingBillInfo.filter.includes(8)"
                        (click)="selMedium(8)" [disabled]="this.currentBuildingBillInfo.haveFilter.indexOf(8)===-1">

                        <mat-icon class="meter_icon8">luggage</mat-icon> Radiatori</mat-button-toggle>
                    <mat-button-toggle [checked]="this.currentBuildingBillInfo.filter.includes(6)"
                        (click)="selMedium(6)" [disabled]="this.currentBuildingBillInfo.haveFilter.indexOf(6)===-1">
                        <mat-icon class="meter_icon6">water_drop</mat-icon>Acqua calda sanitaria
                    </mat-button-toggle>
                    <mat-button-toggle [checked]="this.currentBuildingBillInfo.filter.includes(7)"
                        (click)="selMedium(7)" [disabled]="this.currentBuildingBillInfo.haveFilter.indexOf(7)===-1">
                        <mat-icon class="meter_icon7">water_drop</mat-icon>Acqua fredda sanitaria</mat-button-toggle>
                    <mat-button-toggle [checked]="this.currentBuildingBillInfo.filter.includes(4)"
                        (click)="selMedium(4)" [disabled]="this.currentBuildingBillInfo.haveFilter.indexOf(4)===-1">

                        <mat-icon class="meter_icon4">energy_savings_leaf</mat-icon>Riscaldamento
                    </mat-button-toggle>

                </mat-button-toggle-group>
            </section>
            }
            <hr />
            <mat-form-field class="tabcontainer__form__inner--big">
                <mat-label>Titolo bolletta</mat-label>
                <input matInput placeholder="Titolo" [(ngModel)]="currentBuildingBillInfo.title" />
                <button mat-icon-button matSuffix (click)="rebill()" [attr.aria-label]="'Salva'">
                    <mat-icon>save</mat-icon>
                </button>
            </mat-form-field>
            <span>
                @let enabled = getBuildingBillable();
                <button mat-raised-button [disabled]="!enabled" (click)="getBuildingBilling()">
                    @if (enabled) {
                    <mat-icon>receipt_long</mat-icon>
                    } @else {
                    <mat-icon>do_not_disturb_on</mat-icon>
                    }
                    Report Condominio</button>
                &nbsp;
                <button mat-raised-button aria-hidden="false" [disabled]="!enabled" (click)="getAllBill()">
                    @if (enabled) {
                    <mat-icon>receipt_long</mat-icon>
                    } @else {
                    <mat-icon>do_not_disturb_on</mat-icon>

                    }
                    Report appartamenti

                </button>
                <button mat-raised-button [disabled]="!enabled" (click)="getBuildingWEBilling()">
                    @if (enabled) {
                    <mat-icon>receipt_long</mat-icon>
                    } @else {
                    <mat-icon>do_not_disturb_on</mat-icon>
                    }
                    Report Acqua e energia</button>
                &nbsp;

            </span>
            <hr />
            @if (currentBuildingBillInfo.billComponents===1) {
            <div class="costBar">
                <mat-form-field class="field field__percs">
                    <mat-label>Costo riscaldamento</mat-label>
                    <input matInput placeholder="Costo riscaldamento" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.buildingHeatCost" />
                    <span matSuffix class="munit">€</span>
                </mat-form-field>
                <mat-form-field class="field field__percs">
                    <mat-label>Costo energia</mat-label>
                    <input matInput placeholder="Costo energia" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.buildingEnergyCost" />
                    <span matSuffix class="munit">€</span>
                </mat-form-field>
                <mat-form-field class="field field__percs">
                    <mat-label>Costo conduzione</mat-label>
                    <input matInput placeholder="Costo conduzione" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.buildingManutCost" />
                    <span matSuffix class="munit">€</span>
                </mat-form-field>
                <mat-form-field class="field field__percs">
                    <mat-label>Costo mantenimento</mat-label>
                    <input matInput placeholder="Costo mantenimento" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.buildingMantainCost" />
                    <span matSuffix class="munit">€</span>
                </mat-form-field>

                <mat-form-field class="field field__percs">
                    <mat-label>% volontaria</mat-label>
                    <input matInput placeholder="% volontaria" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.voluntaryPerc" />
                    <span matSuffix class="munit">%</span>
                </mat-form-field>

                <mat-form-field class="field field__percs">
                    <mat-label>% involontaria</mat-label>
                    <input matInput placeholder="% involontaria" [disabled]="!this.currentBilling.draft"
                        [(ngModel)]="currentBuildingBillInfo.involuntaryPerc" />
                    <span matSuffix class="munit">%</span>
                </mat-form-field>
                <span>
                    @if (this.currentBilling.draft) {
                    <button mat-raised-button (click)="submit()"><mat-icon>restart_alt</mat-icon> Ricalcola</button>
                    }
                </span>
                <span>
                    <div>Costi volontari: {{ getCost(currentBuildingBillInfo.voluntaryCost) }}</div>
                    <div>Costi involontari: {{ getCost(currentBuildingBillInfo.involuntaryCost) }}</div>
                    <div>Errore calcolo: {{ getErrorCost() }}</div>
                </span>
            </div>
            <hr />
            }

            @for (element of currentBuildingBillInfo.apartments; track $index) {
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ element.name }}</mat-panel-title>
                    <mat-panel-description>
                        @if (getBillable(element)) {
                        <mat-icon class="coloring__green selectable" (click)="getBill(element)">receipt_long</mat-icon>
                        }
                        @else {
                        <mat-icon class="coloring__red">error_outline</mat-icon>
                        }
                        &nbsp; Contabilizzatori calcolati &nbsp; {{getBillableNumber(element)}}
                        &nbsp; {{getBillableManual(element)}}
                        &nbsp; {{getBillableDeleted(element)}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <table class="devTable">
                    @for (dev of element.meterDevices; track dev; ) {
                    @if (!dev.hidden) {
                    <tr [ngClass]="{'trDel': dev.deleted}">
                        <td (click)="selectDevice(dev)">
                            {{ dev.serial }}
                        </td>
                        <td (click)="selectDevice(dev)">
                            {{ dev.name }}
                        </td>
                        <td (click)="selectDevice(dev)">
                            {{dataservice.getMediumName(dev.medium)}}
                        </td>
                        <td>
                            <span class="meterTotal" (click)="selTotal(dev, element)"
                                [ngClass]="{ 'meterStats--selected': dev.billable}"
                                class="meterStats--warn{{dev.dataCalcMode}}">
                                <div>{{getBillStartDate(currentBuildingBillInfo.reference)}}</div>
                                <!-- <div>{{getValueStr(dev, dev.billTotal)}}</div>-->
                                @if (dev.dataCalcMode===0) {
                                @if (dev.medium===8) {
                                <div>{{ getValueStr(dev,
                                    dev.billTotal)
                                    }}&nbsp;[{{ getValueStr(dev, dev.readoutTotal) }}]
                                </div>
                                } @else {
                                <div>{{ getValueStr(dev, dev.billTotal) }} {{dataservice.getMediumUnit(dev.medium)}}
                                </div>
                                }
                                }
                                @if (dev.dataCalcMode===1) {
                                @if (dev.medium===8) {
                                <div>{{ getValueStr(dev, dev.billTotal) }}&nbsp;[{{ getValueStr(dev, dev.fakeData) }}]
                                </div> } @else {
                                <div>{{ getValueStr(dev, dev.billTotal) }}{{dataservice.getMediumUnit(dev.medium)}}
                                </div>
                                }
                                <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                }
                                @if (dev.dataCalcMode===2) {
                                <div>{{ getValueStr(dev, dev.billTotal) }}{{dataservice.getMediumUnit(dev.medium)}}
                                </div>
                                <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                }
                            </span>
                        </td>
                        <td>
                            <div class="devStatConatiner">
                                @for (stat of dev.stats; track stat; let i=$index) {
                                <span class="meterStats meterStats--small"
                                    class="meterStats--warn{{stat.dataCalcMode}}">
                                    <div>{{ getStatDate(stat) }}</div>
                                    @if (stat.dataCalcMode===0) {
                                    @if (dev.medium===8) {
                                    <div>{{ getValueStr(dev, stat.value)
                                        }}&nbsp;[{{ getValueStr(dev, stat.readoutValue) }}]
                                    </div>
                                    } @else {
                                    <div>{{ getValueStr(dev, stat.value) }}</div>
                                    }
                                    }
                                    @if (stat.dataCalcMode===1) {
                                    @if (dev.medium===8) {
                                    <div>{{ getValueStr(dev, stat.fakeData) }}&nbsp;[{{ getValueStr(dev, stat.value) }}]
                                    </div> } @else {
                                    <div>{{ getValueStr(dev, stat.value) }}</div>
                                    }
                                    <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                    }
                                    @if (stat.dataCalcMode===2) {
                                    <div>{{ getValueStr(dev, stat.value) }}</div>
                                    <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                    }
                                </span>
                                }

                            </div>
                        </td>

                        <!--:{{ getIndex(element, dev) }}-->
                    </tr>
                    }}
                </table>
            </mat-expansion-panel>
            }


            <!--
            <table mat-table [dataSource]="this.currentBuildingBillInfo.apartments" class="mat-elevation-z8">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef>Num.</th>
                    <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
                </ng-container>
                <ng-container matColumnDef="pname">
                    <th mat-header-cell *matHeaderCellDef>Proprietario.</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="apatotal">
                    <th mat-header-cell *matHeaderCellDef>Letture</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.meterTotalValue }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="billable">
                    <th mat-header-cell *matHeaderCellDef>
                        <button mat-button aria-hidden="false" (click)="getAllBill()">
                            <mat-icon class="coloring__green selectable">receipt_long</mat-icon>
                            Bollette

                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        @if (getBillable(element)) {
                        <mat-icon class="coloring__green selectable" (click)="getBill(element)">receipt_long</mat-icon>
                        }
                        @else {
                        <mat-icon class="coloring__red">error_outline</mat-icon>
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="devices">
                    <th mat-header-cell *matHeaderCellDef>Contabilizzatori</th>
                    <td mat-cell *matCellDef="let element">
                        @for (dev of element.meterDevices; track dev; ) {

                        <div>
                            <span class="devInfo" (click)="selectDevice(dev)">
                                {{ dev.serial }} &nbsp;{{ dev.name }} &nbsp;{{dataservice.getMediumName(dev.medium)}}
                            </span>
                            <div class="devStatConatiner">



                                @for (stat of dev.stats; track stat; let i=$index) {
                                <span class="meterStats" (click)="selStat(element, dev, i)"
                                    [ngClass]="{ 'meterStats--selected': i === getIndex(element, dev)}"
                                    class="meterStats--warn{{stat.dataCalcMode}}">
                                    <div>{{ getStatDate(stat) }}</div>
                                    @if (stat.dataCalcMode===0) {
                                    @if (dev.medium===8) {
                                    <div>{{ getValueStr(dev, stat.readedValue) }}&nbsp;[{{ getValueStr(dev, stat.value)
                                        }}]
                                    </div>
                                    } @else {
                                    <div>{{ getValueStr(dev, stat.value) }}</div>
                                    }
                                    }
                                    @if (stat.dataCalcMode===1) {
                                    @if (dev.medium===8) {
                                    <div>{{ getValueStr(dev, stat.fakeData) }}&nbsp;[{{ getValueStr(dev, stat.value) }}]
                                    </div> } @else {
                                    <div>{{ getValueStr(dev, stat.value) }}</div>
                                    }
                                    <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                    }
                                    @if (stat.dataCalcMode===2) {
                                    <div>{{ getValueStr(dev, stat.value) }}</div>
                                    <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                    }
                                </span>
                                }
                                <span class="meterTotal" (click)="selTotal(dev, element)"
                                    [ngClass]="{ 'meterStats--selected': dev.billable}"
                                    class="meterStats--warn{{dev.dataCalcMode}}">
                                    <div>{{getBillStartDate(currentBuildingBillInfo.reference)}}</div>
                                    
                                    @if (dev.dataCalcMode===0) {
                                    @if (dev.medium===8) {
                                    <div>{{ getValueStr(dev, dev.readoutTotal) }}&nbsp;[{{ getValueStr(dev,
                                        dev.billTotal)
                                        }}]
                                    </div>
                                    } @else {
                                    <div>{{ getValueStr(dev, dev.billTotal) }}</div>
                                    }
                                    }
                                    @if (dev.dataCalcMode===1) {
                                    @if (dev.medium===8) {
                                    <div>{{ getValueStr(dev, dev.fakeData) }}&nbsp;[{{ getValueStr(dev, dev.billTotal)
                                        }}]
                                    </div> } @else {
                                    <div>{{ getValueStr(dev, dev.billTotal) }}</div>
                                    }
                                    <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                    }
                                    @if (dev.dataCalcMode===2) {
                                    <div>{{ getValueStr(dev, dev.billTotal) }}</div>
                                    <mat-icon aria-hidden="false" class="meterStats__fixicon">auto_fix_high</mat-icon>
                                    }
                                </span>
                            </div>


                           
                        </div>
                        }
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
                <tr mat-row *matRowDef="let row; columns: getColumns()" (click)="select(row)"></tr>

                
            </table>
            <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina">
            </mat-paginator>-->
        </div>
    </div>
    }
</div>
<mat-autocomplete #autocomplete="matAutocomplete">
    <mat-option *ngFor="let item of findSerials" [value]="item.serial" class="findtab">
        <mat-icon class="findicon--ko" *ngIf="item.apartment">report_problem</mat-icon>
        <mat-icon class="findicon--ok" *ngIf="item.apartment == undefined">check_circle</mat-icon>

        <span>{{ dataservice.getStringOrNull(item.serial) }} - {{ dataservice.getStringOrNull(item.name) }} - {{
            dataservice.getStringOrNull(item.apartment?.name) }} - {{ dataservice.getStringOrNull(item.name) }}
        </span></mat-option>
</mat-autocomplete>
<h2 mat-dialog-title>Device {{ dataservice.getSerial(currentItem) }} - {{ dataservice.getManu(currentItem) }} - {{
    dataservice.getMedium(currentItem) }}</h2>
<mat-dialog-content>
    <mat-tab-group class="content">

        @if(currentItem.apartment){
        <mat-tab label="Anagrafica">

            <div class="card-container">
                <div class="hsep--h5"></div>
                <div class="heatsize">
                    <mat-form-field class="field-full-width">
                        <mat-label>Nome</mat-label>
                        <input matInput placeholder="Nome" [(ngModel)]="currentItem.name" />
                    </mat-form-field>
                </div>
                <div class="heatsize">
                    <mat-form-field class="field-full-width">
                        <mat-label>Seriale</mat-label>
                        <input matInput placeholder="Seriale" [(ngModel)]="currentItem.serial"
                            [matAutocomplete]="autocomplete" (ngModelChange)="find()" />
                    </mat-form-field>

                    <mat-form-field class="field-full-width">
                        <mat-label>Area ripartitore</mat-label>
                        <mat-select [(ngModel)]="currentItem.mediumArea">
                            <mat-option *ngFor="let area of dataservice.mediumAreas" [value]="area.medium">
                                {{area.medium}}-{{ area.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="hsep--h5"></div>
                <mat-form-field class="field-full-width">
                    <mat-label>Tipologia</mat-label>
                    <input matInput placeholder="Tipologia" [(ngModel)]="currentItem.medium" type="number" />
                </mat-form-field>
                <!--dataSource-->
             <!--   <mat-form-field>
                    <mat-label>Metodo calcolo</mat-label>
                    <mat-select [(ngModel)]="currentItem.dataSource">
                        <mat-option [value]="0">Dispositivo presente</mat-option>
                        <mat-option [value]="1">Media letture appartamento</mat-option>
                        <mat-option [value]="2">Stima lettura da formula</mat-option>
                    </mat-select>
                </mat-form-field>-->
                <mat-form-field class="field-full-width">
                    <mat-label>Note</mat-label>
                    <input matInput placeholder="Note" [(ngModel)]="currentItem.note" />
                </mat-form-field>
                <mat-form-field *ngIf="currentItem.medium === 8">
                    <mat-label>Dati in chiaro</mat-label>
                    <mat-select [(ngModel)]="currentItem.deviceDetails.forceCleanValue">
                        <mat-option *ngIf="currentItem.apartment.building.metersClearValue" [value]="0">Si (da
                            stabile)</mat-option>
                        <mat-option *ngIf="!currentItem.apartment.building.metersClearValue" [value]="0">No (da
                            stabile)</mat-option>
                        <mat-option [value]="1">No (Forzato)</mat-option>
                        <mat-option [value]="2">Si (Forzato)</mat-option>
                        <mat-option [value]="3">Usa Kq personalizzato</mat-option>
                        <mat-option [value]="4">Usa K personalizzato</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="currentItem.medium === 7">
                    <mat-label>Dati in chiaro</mat-label>
                    <mat-select [value]="0">
                        <mat-option [value]="0">Si</mat-option>
                    </mat-select>
                </mat-form-field>
                @if (currentItem.deviceDetails.forceCleanValue == 3) {
                <mat-form-field class="full-size-form">
                    <mat-label>Potenza in W</mat-label>
                    <input matInput placeholder="Potenza" type="number"
                        [(ngModel)]="currentItem.deviceDetails.customKq" />
                </mat-form-field>
                }
                @if (currentItem.deviceDetails.forceCleanValue == 4) {

                <mat-form-field class="full-size-form">
                    <mat-label>Valore K</mat-label>
                    <input matInput placeholder="Valore K" type="number" [(ngModel)]="currentItem.deviceDetails.customK"
                        [disabled]="currentItem.deviceDetails.forceCleanValue != 4" />
                </mat-form-field>
                }
                <mat-checkbox [(ngModel)]="currentItem.deleted">Eliminato</mat-checkbox>

            </div>
        </mat-tab>
        }
        @if (currentItem.deviceDetails && currentItem.medium === 8 &&
        (currentItem.deviceDetails.forceCleanValue!=3)) {
        <mat-tab label="Parametri">



            <div class="heatsize">
                <mat-form-field class="heatsize__number">
                    <mat-label>L (mm)</mat-label>
                    <input matInput placeholder="Larghezza" [(ngModel)]="currentItem.deviceDetails.width"
                        (input)="changeRadiator()" />
                </mat-form-field>
                <mat-form-field class="heatsize__number">
                    <mat-label>A (mm)</mat-label>
                    <input matInput placeholder="Altezza" [(ngModel)]="currentItem.deviceDetails.height"
                        (input)="changeRadiator()" />
                </mat-form-field>
                <mat-form-field class="heatsize__number">
                    <mat-label>P (mm)</mat-label>
                    <input matInput placeholder="Profondità" [(ngModel)]="currentItem.deviceDetails.depth"
                        (input)="changeRadiator()" /></mat-form-field>
            </div>

            <div class="heatsize">
                <mat-form-field class="heatsize__number">
                    <mat-label>Elementi</mat-label>
                    <input matInput placeholder="Elementi" [(ngModel)]="currentItem.deviceDetails.elements" />
                </mat-form-field>
                <mat-form-field class="heatsize__number">
                    <mat-label>Colonne</mat-label>
                    <input matInput placeholder="Colonne" [(ngModel)]="currentItem.deviceDetails.columns" />
                </mat-form-field>
                <mat-form-field class="heatsize__number">
                    <mat-label>K Rip.</mat-label>
                    <input matInput placeholder="K Rip" [(ngModel)]="currentItem.deviceDetails.krip" />
                </mat-form-field>
            </div>
            <mat-form-field class="field-full-width">
                <mat-label>Tipo radiatore</mat-label>
                <mat-select [(ngModel)]="currentItem.deviceDetails.radiatorParameter"
                    [compareWith]="dataservice.compareRadiatorParametersById">>
                    <mat-option *ngFor="let rad of dataservice.radiators" [value]="rad" (click)="changeRadiator()">
                        {{ rad.code }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="details">
                <!--  <div>{{ currentItem.details.radiatorParameter.code }}</div>-->

                <img src="/assets/radiator/{{ currentItem.deviceDetails.radiatorParameter?.image }}" />
                <div class="details__kvalues">
                    <div class="details__kvalues__k">
                        K: <b>{{ currentItem.deviceDetails.k.toFixed(2) }}</b>
                    </div>
                    <div class="details__kvalues__k">
                        Kq: <b>{{ currentItem.deviceDetails.kq.toFixed(2) }}W</b>
                    </div>
                    <div class="details__kvalues__k">
                        Kc: <b>{{ currentItem.deviceDetails.radiatorParameter?.k75_2f_205.toFixed(2) }}W</b>
                    </div>
                    <div class="details__kvalues__k">
                        Cj: <b>{{ currentItem.deviceDetails.radiatorParameter?.cj }}</b>
                    </div>

                </div>



                <!--<app-dictcombo label="Codice Radiatore" [(value)]="currentItem.details.radiatorCode" key="kctype"></app-dictcombo>-->
                <!--    <app-dictcombo label="Tipo KC" [(value)]="currentItem.details.kcType" key="kctype"></app-dictcombo>
        <app-dictcombo label="Tipo KC" [(value)]="currentItem.details.kcType" key="kctype"></app-dictcombo>-->
            </div><button mat-raised-button (click)="reloadK()" color="warn"
                [disabled]="!modified"><mat-icon>restart_alt</mat-icon>Ricalcola K</button>

        </mat-tab>
        }
        <mat-tab label="Valori statistici">

            <div class="statcont">
                <table class="tabledata">
                    <tr>
                        <th></th>
                        <th>Valore attuale</th>
                        <th>Valore statistico</th>
                        <th>Valore SET</th>

                    </tr>
                    <tr>
                        <td>Data</td>
                        <td>{{currentItem.readoutTime| date:"dd/MM/YYYY"}}</td>
                        <td>{{currentItem.statisticalDate | date:"MM/YYYY"}}</td>
                        <td>{{currentItem.setDate| date:"MM/YYYY"}}</td>
                    </tr>

                    <tr>
                        <td>Calcolata</td>
                        <td>{{ dataservice.getDecValue(currentItem, currentItem.actualValue)
                            }}&nbsp;{{dataservice.getMediumUnit(currentItem.medium)}}</td>
                        <td>{{ dataservice.getDecValue(currentItem, currentItem.statValue)
                            }}&nbsp;{{dataservice.getMediumUnit(currentItem.medium)}}</td>
                        <td>{{ dataservice.getDecValue(currentItem, currentItem.setValue)
                            }}&nbsp;{{dataservice.getMediumUnit(currentItem.medium)}}</td>
                    </tr>
                    <tr>
                        <td>Lettura</td>
                        <td>{{ currentItem.readoutActualValue }}</td>

                        <td>{{ currentItem.readoutStatValue }}</td>
                        <td>{{ currentItem.readoutSetValue }}</td>
                    </tr>
                </table>


                <table class="tabledata">
                    <tr>
                        <th>Data</th>
                        <th>Valore calcolato</th>
                        <th>Lettura</th>
                        <!-- <th>Delta</th>-->
                        <th>Delta</th>
                    </tr>

                    <tr class="statcont__item" *ngFor="let hist of sortedStats()">
                        @if (getIfPart(hist)) {
                        <td class="statcont__item__dtcell colors--orange">

                            {{currentItem.readoutTime| date:"dd"}}&nbsp;

                            {{ getHistDate(hist) }}

                        </td>
                        } @else {
                        <td class="statcont__item__dtcell">
                            {{ getHistDate(hist) }}

                        </td>
                        }
                        <td class="statcont__item__cell"><b>{{ (hist.value===-1)?"N/A":
                                dataservice.getDecValue(currentItem, hist.value) }}
                                {{dataservice.getMediumUnit(currentItem.medium)}}</b>
                        </td>
                        <td class="statcont__item__cell">{{ hist.readedValue }}</td>

                        <!--   <td class="statcont__item__cell">{{ hist.readedDeltaValue }}</td>-->
                        <td class="statcont__item__cell">{{
                            (hist.deltaValue===-1)?"N/A":dataservice.getDecValue(currentItem, hist.deltaValue) }}
                            {{dataservice.getMediumUnit(currentItem.medium)}}</td>
                        <!--   <div class="statcont__item__cell">{{ hist.forcedValue }}</div>-->
                    </tr>
                </table>
            </div>

        </mat-tab>
        <mat-tab label="Dati ricevuti dai concentratori">
            <div class="hsep--h5"></div>
            <div *ngIf="currentItem.concentrators">
                <div class="concentrators" *ngFor="let item of currentItem.concentrators">{{ item.mac }} {{
                    item.description }}</div>
            </div>

        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">Chiudi</button>
    <button mat-raised-button (click)="saveDetail()" color="warn">Aggiorna</button>
    <!--    <button mat-raised-button (click)="Duplicate()" color="warn">Clona nell'appartamento</button>-->
    <button mat-raised-button (click)="deleteItem()" color="warn">ELIMINA</button>
</mat-dialog-actions>
import { ISortParams, IConcentrator, IBreadcrumbData, IMeterDeviceStatBillingInfo, IApartment, IMeterDeviceStats } from './../../interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { IMeterDevice, IBuilding } from 'src/interfaces';
import { AdddevicesComponent } from '../adddevices/adddevices.component';
import { DataserviceService } from '../dataservice.service';
import { HeatdetailComponent } from '../heatdetail/heatdetail.component';
import { CdialogComponent } from '../cdialog/cdialog.component';
import { ConfirmdialogComponent } from '../confirmdialog/confirmdialog.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatButton, MatIconButton } from '@angular/material/button';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-deviceslist',
    templateUrl: './deviceslist.component.html',
    styleUrls: ['./deviceslist.component.scss'],
    standalone: true,
    imports: [NgIf, ProgressspinnerComponent, BreadcrumbnavComponent, MatButton, MatFormField, MatLabel, MatInput, FormsModule, MatIconButton, MatIcon, NgClass, MatButtonToggleGroup, MatButtonToggle, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatSortHeader, NgFor, MatTooltip, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, DatePipe]
})
export class DeviceslistComponent implements OnInit {
    currentItem: IApartment | IBuilding;
    currentConc: IConcentrator;
    displayedColumnsStat: string[] = ['position', 'devid', 'cname', 'location', 'medium', 'devprod', 'status', 'energy', 'setval', 'setdate', 'readout', 'update', 'statdat', 'note', 'action'];
    displayedColumnsPara: string[] = ['position', 'devid', 'cname', 'location', 'medium', 'devprod', 'status', 'errdate', 'size', 'rtype', 'valk', 'ksource', 'action'];
    displayedColumnsRead: string[] = ['position', 'devid', 'cname', 'location', 'medium', 'devprod', 'status', 'errdate', 'concentrators', 'action'];
    displayedColumnsStats: string[] = ['position', 'devid', 'cname', 'medium', 'stats', 'action'];
    Arr = Array;
    devices: IMeterDevice[] = [];
    sortedData: IMeterDevice[] = [];
    currentId: number;
    currentReq: string;
    alive = 0;
    old = 0;
    dead = 0;
    berror = 0;
    derr = 0;
    dorf = 0;
    breadcrumb: IBreadcrumbData[] = [];

    fOffline = false;
    fError = false;
    fdError = false;
    fNotUpdated = false;
    fAlive = false;
    forf = false;
    dataSource: MatTableDataSource<IMeterDevice>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    toFind = '';
    dataSpecs = '0';
    progress = false;
    sortParams: ISortParams = { active: '', direction: '', enabled: false };

    constructor(private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            this.currentId = parseInt(map.get('id'), 10);
            this.currentReq = map.get('from');
            if (this.currentReq === 'apartment') {
                this.dataservice.getApartmet(this.currentId).subscribe(
                    (data) => {
                        this.currentItem = data as IApartment;
                        this.breadcrumb.push({ label: 'HOME', url: '/' });
                        this.breadcrumb.push({ label: 'stabili', url: '/buildings/' + this.currentItem.building.name });
                        this.breadcrumb.push({ label: this.currentItem.building.name, url: '/apartments/' + this.currentItem.building.buildingId });
                        this.breadcrumb.push({ label: this.currentItem.name, url: 'javascript:;' });
                        this.breadcrumb.push({ label: 'CONTABILIZZATORI', url: 'javascript:;' });
                    },
                    () => {
                        this.dataservice.goHome();
                    }
                );
            }
            if (this.currentReq === 'all') {
                this.breadcrumb.push({ label: 'HOME', url: '/' });
                this.breadcrumb.push({ label: 'CONTABILIZZATORI', url: 'javascript:;' });
            }
            if (this.currentReq === 'building') {
                this.dataservice.getBuilding(this.currentId).subscribe(
                    (data) => {
                        this.currentItem = data as IBuilding;
                        this.breadcrumb.push({ label: 'HOME', url: '/' });
                        this.breadcrumb.push({ label: 'stabili', url: '/buildings/' + this.currentItem.name });
                        this.breadcrumb.push({ label: this.currentItem.name, url: '/apartments/' + this.currentItem.buildingId });
                        this.breadcrumb.push({ label: 'CONTABILIZZATORI', url: 'javascript:;' });
                    },
                    () => {
                        this.dataservice.goHome();
                    }
                );
            }
            if (this.currentReq === 'concentrator') {
                this.dataservice.getConcentrator(this.currentId).subscribe(
                    (data) => {
                        this.currentConc = data as IConcentrator;
                        this.breadcrumb.push({ label: 'HOME', url: '/' });
                        this.breadcrumb.push({ label: 'concentratori', url: '/concentrators' });
                        this.breadcrumb.push({ label: this.currentConc.description, url: '/concentrator/' + this.currentConc.concentratorId });
                        this.breadcrumb.push({ label: 'CONTABILIZZATORI', url: 'javascript:;' });
                    },
                    () => {
                        this.dataservice.goHome();
                    }
                );
            }
            this.progress = true;
            if (this.currentReq === 'all') {
                let filter = this.toFind.trim();
                if (filter === '') {
                    filter = '*';
                }
                this.dataservice.getMeters(filter, 'all').subscribe((data) => {
                    this.devices = data as IMeterDevice[];
                    this.calcAlive();
                    this.progress = false;
                    this.sortedData = this.devices.slice();
                    this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
                    this.dataSource.paginator = this.paginator;
                });
            } else {
                this.dataservice.getMeters(this.currentId, this.currentReq).subscribe((data) => {
                    this.devices = data as IMeterDevice[];
                    this.calcAlive();
                    this.progress = false;
                    this.sortedData = this.devices.slice();
                    this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
                    this.dataSource.paginator = this.paginator;
                });
            }
        });
    }
    refresh() {
        if (this.currentReq === 'apartment') {
            this.dataservice.getApartmet(this.currentId).subscribe(
                (data) => {
                    this.currentItem = data as IApartment;
                },
                () => {
                    this.dataservice.goHome();
                }
            );
        }
        if (this.currentReq === 'building') {
            this.dataservice.getBuilding(this.currentId).subscribe(
                (data) => {
                    this.currentItem = data as IBuilding;
                },
                () => {
                    this.dataservice.goHome();
                }
            );
        }
        this.progress = true;
        this.dataservice.getMeters(this.currentId, this.currentReq).subscribe((data) => {
            this.devices = data as IMeterDevice[];
            this.calcAlive();
            this.progress = false;
            this.sortedData = this.devices.slice();
            this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
            this.dataSource.paginator = this.paginator;
        });
    }

    find() {
        if (this.currentReq === 'all') {
            let filter = this.toFind.trim();
            if (filter === '' || filter.length > 4) {
                if (filter === '') {
                    filter = '*';
                }
                const filterApi = this.forf ? 'or' : 'all';
                this.dataservice.getMeters(filter, filterApi).subscribe((data) => {
                    this.devices = data as IMeterDevice[];
                    this.calcAlive();
                    this.progress = false;
                    this.sortedData = this.devices.slice();
                    this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
                    this.dataSource.paginator = this.paginator;
                });
            }
        } else {
            this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
            this.dataSource.paginator = this.paginator;
            // console.log('changed!!', this.toFind);
        }
    }

    getDisplayedColumns() {
        switch (this.dataSpecs) {
            case '0':
                return this.displayedColumnsStat;
            case '1':
                return this.displayedColumnsPara;
            case '2':
                return this.displayedColumnsRead;
            case '3':
                return this.displayedColumnsStats;
        }
    }

    getConcentrators(item: IMeterDevice) {
        let retval = '';
        for (const con of item.concentrators) {
            if (con.description) {
                retval += '<span>' + con.description + '</span>';
            } else {
                retval += '<span>' + con.description + '</span>';
            }
        }
    }

    sel(item: string) {
        switch (item) {
            case 'all':
                this.fError = false;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.fAlive = false;
                this.forf = false;
                break;
            case 'or':
                this.fError = false;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.fAlive = false;
                this.forf = !this.forf;
                break;
            case 'er':
                this.fError = !this.fError;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.forf = false;
                this.fAlive = false;
                break;
            case 'der':
                this.fdError = !this.fdError;
                this.fError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.forf = false;
                this.fAlive = false;
                break;
            case 'nu':
                this.fNotUpdated = !this.fNotUpdated;
                this.fdError = false;
                this.fError = false;
                this.fOffline = false;
                this.forf = false;
                this.fAlive = false;
                break;
            case 'ol':
                this.fOffline = !this.fOffline;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fError = false;
                this.forf = false;
                this.fAlive = false;
                break;
            case 'al':
                this.fAlive = !this.fAlive;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.forf = false;
                this.fError = false;
                break;
        }
        this.find();
    }

    getStatDate(item: IMeterDeviceStatBillingInfo | IMeterDeviceStats) {
        if (item.mm === -1) {
            return 'CUST';
        }
        const month = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];
        return month[item.mm] + ' ' + (item.yy - 2000);
    }

    sortedStat(element: IMeterDevice) {
        return element.statistics.sort((a, b) => this.dataservice.compareStat(a, b, false));
    }

    getDeviceStats(apa: IApartment, dev: IMeterDevice) {
        return dev.statistics;
    }

    getValueStr(item: number) {
        if (item === -1) {
            return 'N/D';
        }
        return item;
    }

    SortChange(event) {
        // console.log(event);
        const data = this.devices.slice();
        this.sortParams = event as ISortParams;
        this.sortParams.enabled = this.sortParams.direction !== '';
        if (this.sortParams.enabled) {
            this.sortedData = data.sort((a, b) => {
                switch (this.sortParams.active) {
                    case 'devid':
                        return this.dataservice.compare(a.serial, b.serial, this.sortParams.direction);
                    case 'cname':
                        return this.dataservice.compare(a.name, b.name, this.sortParams.direction);
                    case 'location':
                        return this.dataservice.compare(this.getLocation(a), this.getLocation(b), this.sortParams.direction);
                    case 'medium':
                        return this.dataservice.compare(this.dataservice.getMedium(a), this.dataservice.getMedium(b), this.sortParams.direction);
                    case 'valk':
                        return this.dataservice.compare(this.getKVal(a), this.getKVal(b), this.sortParams.direction);
                    case 'rtype':
                        return this.dataservice.compare(this.getRadiatorType(a), this.getRadiatorType(b), this.sortParams.direction);
                    case 'ksource':
                        return this.dataservice.compare(a.deviceDetails.forceCleanValue, b.deviceDetails.forceCleanValue, this.sortParams.direction);
                    case 'status':
                        return this.dataservice.compare(a.status & 0xff, b.status & 0xff, this.sortParams.direction);
                    case 'readout':
                        return this.dataservice.compareDate(a.readoutTime, b.readoutTime, this.sortParams.direction);
                    case 'update':
                        return this.dataservice.compareDate(a.lastUpdateTime, b.lastUpdateTime, this.sortParams.direction);
                    case 'statdate':
                        return this.dataservice.compareDate(a.statisticalDate, b.statisticalDate, this.sortParams.direction);
                    default:
                        return 0;
                }
            });
        } else {
            this.sortedData = this.devices.slice();
        }
        this.find();
    }

    getItemId() {
        if (this.currentReq === 'apartment') {
            return (this.currentItem as IApartment).building.buildingId;
        }
        if (this.currentReq === 'building') {
            return (this.currentItem as IBuilding).apartments;
        }
    }

    getItemName() {
        if (this.currentReq === 'apartment') {
            return (this.currentItem as IApartment).building.name;
        }
        if (this.currentReq === 'building') {
            return (this.currentItem as IBuilding).name;
        }
    }
    getLocation(item: IMeterDevice) {
        let caption = '';
        if (item.apartment) {
            caption = item.apartment.name;
            if (item.apartment.building) {
                caption += ' ' + item.apartment.building.name;
            }
        }

        if (caption) {
            return caption.trim();
        }
        return '';
    }
    getStatusTT(item: IMeterDevice) {
        console.log('blurb');
        let retval = '';
        if (item.deleted) {
            return 'Dispositivo eliminato';
        }
        if (item.status & 0x1000) {
            retval = 'Dispositivo aggiornato da file REP\n';
        } else {
            if (this.getDeviceUpdated(item) === 0) {
                retval = 'Dispositivo non aggiornato\n'
            } else {
                if ((item.status & 0xff) === 0) {
                    return 'Nessun problema rilevato\n';
                }
            }
        }

        if (this.getStatusIcon(item, 4) && this.getStatusIcon(item, 3)) {
            retval += 'Manomissione \n';
        } else if (this.getStatusIcon(item, 4)) {
            retval += 'Errore permanente \n';
        } else if (this.getStatusIcon(item, 3)) {
            retval += 'Errore temporaneo \n';
        }
        if (this.getStatusIcon(item, 2)) {
            retval += 'Batteria scarica \n';
        }
        return retval;
    }
    clear() {
        this.dataservice.deleteMeters(this.currentId, this.currentReq).subscribe(() => {
            this.dataservice.toastr.success('Eliminazione effettuata');
            this.dataservice.getMeters(this.currentId, this.currentReq).subscribe((data) => {
                this.devices = data as IMeterDevice[];
                this.calcAlive();
                this.sortedData = this.devices.slice();
                this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
                this.dataSource.paginator = this.paginator;
            });
        });
    }

    ngOnInit() { }

    select(item) {

        this.dataservice.getMeterDetail(item.meterDeviceId).subscribe((data) => {
            const dialogRef = this.dialog.open(HeatdetailComponent, {
                data: data as IMeterDevice,
                width: '90vw',
                height: '90vh'
            });

            dialogRef.afterClosed().subscribe((result) => {
                /*  if (result) {
                    // console.log(result);
                    this.dataservice.saveMeter(result as IMeterDevice).subscribe((x) => {
                        this.dataservice.toastr.success('Salvataggio eseguito');
                    });
                }*/
                this.refresh();
            });
        });
    }

    getKVal(element: IMeterDevice) {
        if (element.deviceDetails.forceCleanValue === 3) {
            return '<span class="kval kval--custom">' + element.deviceDetails.customKq.toFixed(2) + '</span>';
        }
        return element.deviceDetails.k.toFixed(2);
    }

    getKsource(element: IMeterDevice) {
        //  // console.log(element);
        if (element.deviceDetails) {
            switch (element.deviceDetails.forceCleanValue) {
                case 0:
                    if (element.apartment.building) {
                        if (element.apartment.building.metersClearValue) {
                            return 'Si';
                        } else {
                            return 'No';
                        }
                    } else if (this.currentReq === 'apartment') {
                        if ((this.currentItem as IApartment).building.metersClearValue) {
                            return 'Si';
                        } else {
                            return 'No';
                        }
                    }
                    break;
                case 1: {
                    return 'No (forzato)';
                }

                case 2: {
                    return 'Si (forzato)';
                }

                case 3: {
                    return 'val K pers.';
                }

                default:
                    break;
            }
        }
        return 'n/a';
    }

    getRadiatorSize(element: IMeterDevice) {
        if (element.deviceDetails) {
            return element.deviceDetails.width + '/' + element.deviceDetails.height + '/' + element.deviceDetails.depth;
        }
        return 'n/a';
    }

    getRadiatorType(element: IMeterDevice) {
        if (element.deviceDetails && element.deviceDetails.radiatorParameter) {
            return element.deviceDetails.radiatorParameter.code;
        }
        return '';
    }

    getClearBulletStyle(element: IMeterDevice) {
        if (element.deviceDetails) {
            switch (element.deviceDetails.forceCleanValue) {
                case 0:
                    if (element.apartment && element.apartment.building) {
                        if (element.apartment.building.metersClearValue) {
                            return { backgroundColor: 'yellowgreen' };
                        } else {
                            return { backgroundColor: 'gray' };
                        }
                    }
                    return { backgroundColor: 'red' };
                case 1:
                    return { backgroundColor: 'black' };
                case 2:
                    return { backgroundColor: 'green' };
                case 3:
                    return { backgroundColor: 'blue' };
            }
        } else {
            return { backgroundColor: 'whitesmoke' };
        }
    }

    getClearTextStyle(element: IMeterDevice) {
        if (element.deviceDetails) {
            switch (element.deviceDetails.forceCleanValue) {
                case 0:
                    if (element.apartment && element.apartment.building) {
                        if (element.apartment.building.metersClearValue) {
                            return 'SI, globale da stabile';
                        } else {
                            return 'NO, globale da stabile';
                        }
                    }
                    return 'NESSUNO STABILE ASSEGNATO!!';
                case 1:
                    return 'NO, valore forzato';
                case 2:
                    return 'SI, valore forzato';
                case 3:
                    return 'Valore K personalizzato';
            }
        } else {
            return 'N/A';
        }
    }

    getBulletStyle(element: IMeterDevice) {
        if (element.lastUpdateTime === undefined || element.lastUpdateTime == null) {
            return 'darkgray';
        }
        const now = new Date().getTime();
        const date = new Date(element.lastUpdateTime + 'Z');
        const diff = (now - date.getTime()) / 1000;
        switch (this.getDeviceUpdated(element)) {
            case 0:
                return { backgroundColor: 'red' };

            case 3:

                if ((element.status & 0xff) === 0) {
                    return { backgroundColor: 'yellow' };
                }
                if ((element.status & 0xff) === 120) {
                    return { backgroundColor: 'orange' };
                }
                break;
            case 2:
                if ((element.status & 0xff) === 0) {
                    return { backgroundColor: 'darkgreen' };
                }
                if ((element.status & 0xff) === 120) {
                    return { backgroundColor: 'orange' };
                }
                break;
            case 1:
                if ((element.status & 0xff) === 0) {
                    return { backgroundColor: 'green' };
                }
                if ((element.status & 0xff) === 120) {
                    return { backgroundColor: 'orange' };
                }
                break;

        }
        return { backgroundColor: 'red' };
    }

    getBulletVisibled(element: IMeterDevice) {
        let visibled = true;

        switch (this.getDeviceUpdated(element)) {
            case 1:
            case 2:
            case 3:
                if ((element.status & 0xff) === 0) {
                    return true;
                }

        }
        return false;
    }

    getIconStyle(element: IMeterDevice) {
        if ((element.status & 0xff) === 0) {
            return { color: 'green' };
        }
        if ((element.status & 0xff) === 120) {
            return { color: 'orange' };
        }
        return { color: 'red' };
    }

    getStatusIcon(element: IMeterDevice, mask: number) {
        // console.log('status', element.status, element.status & (1 << mask));
        // tslint:disable-next-line: no-bitwise
        return ((element.status & 0xff) & (1 << mask)) !== 0;
    }

    getErrorNameIcon(element) {
        switch (((element.status & 0xff) >> 3) & 3) {
            case 0:
                return '';
            case 1:
                return 'report_gmailerrorred';
            case 2:
                return 'broken_image';
            case 3:
                return 'plumbing';
        }
    }

    getErrorStyleColor(element) {
        switch (((element.status & 0xff) >> 3) & 3) {
            case 0:
                return { display: 'none' };
            case 1:
                return { color: 'orange' };
            case 2:
                return { color: 'red' };
            case 3:
                return { color: 'darkred' };
        }
    }


    subtractMonths(date, months) {
        date.setMonth(date.getMonth() - months);

        return date;
    }

    dateValid(data: Date) {
        return new Date(data).getFullYear() !== 1;
    }

    getDeviceClass(device: IMeterDevice) {
        const date = new Date(device.lastUpdateTime + 'Z');
        const now = new Date();

        if (date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
            return 'time time--perfect';
        }
        this.subtractMonths(now, 1);
        if (date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
            return 'time time--good';
        }
        this.subtractMonths(now, 1);
        if (date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
            return 'time time--warn';
        }
        return 'time time--error';

    }

    getDeviceUpdated(device: IMeterDevice): number {
        const date = new Date(device.lastUpdateTime + 'Z');
        const now = new Date();

        if (date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
            return 1;
        }
        this.subtractMonths(now, 1);
        if (date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
            return 2;

        }
        this.subtractMonths(now, 1);
        if (date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
            return 3;
        }
        return 0;

    }
    getTotalLen(num: number) {
        if (num === 1000) {
            return '1000+'
        }
        return num.toFixed();
    }

    getStatusREP(item: IMeterDevice) {
        return (item.status & 0x1000);
    }
    calcAlive() {
        this.alive = 0;
        this.old = 0;
        this.dead = 0;
        this.berror = 0;
        this.derr = 0;
        this.dorf = 0;
        const now = new Date().getTime();
        const nowDt = new Date();
        // nowDt.setMonth(nowDt.getMonth() - 1);
        for (const dev of this.devices) {
            dev.offline = false;
            dev.notUpdated = false;
            dev.error = (dev.status & 0xff) !== 0;
            if (dev.apartment === undefined || dev.apartment == null) {
                this.dorf++;
            }
            if (this.dataservice.getSerialOK(dev, this.currentItem)) {
                dev.dataError = true;
                this.derr++;
            } else {
                if ((dev.status & 0xff) !== 0) {
                    this.berror++;
                }
                if (dev.lastUpdateTime === undefined || dev.lastUpdateTime == null) {
                    this.dead++;
                    dev.offline = true;
                } else {
                    const date = new Date(dev.lastUpdateTime + 'Z');
                    const diff = (now - date.getTime()) / 1000;

                    if (date.getMonth() !== nowDt.getMonth()) {
                        this.old++;
                        this.alive++;

                        dev.notUpdated = true;
                    } else if (diff > 864000) {


                        dev.offline = true;

                        this.dead++;
                    } else {
                        this.alive++;
                    }
                }
            }
        }
    }

    remFind() {
        this.toFind = '';
        this.find();
    }

    applyFilter(x: IMeterDevice): boolean {
        if (this.forf && x.apartment) {
            return false;
        }
        if (this.fAlive && x.offline) {
            return false;
        }
        if (this.fError && !x.error) {
            return false;
        }

        if (this.fdError && !x.dataError) {
            return false;
        }

        if (this.fNotUpdated && !x.notUpdated) {
            return false;
        }

        if (this.fOffline && !x.offline) {
            return false;
        }

        if (this.toFind.length > 3) {
            if (x.serial.indexOf(this.toFind) !== -1) {
                return true;
            }
            if (x.name && x.name.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
            if (this.getLocation(x).toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    getFilteredDevices() {
        const sorted = this.sortedData.filter((x) => this.applyFilter(x));

        return sorted;
    }

    addMeter() {
        const dialogRef = this.dialog.open(AdddevicesComponent, {
            data: this.currentItem,
            width: '90vw',
            height: '90vh'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result);
                this.ngOnInit();
            }
        });
    }

    genStat() { }
}

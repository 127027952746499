import { BillcustomstatComponent } from './../billcustomstat/billcustomstat.component';
import { IBuilding, IApartment, IBuildingBillingInfo, IBillingBuilding, IMeterDevice, IMeterDeviceStatBillingInfo, IBreadcrumbData, IApartmentBillingInfo, IMeterDeviceBillingInfo } from 'src/interfaces';
import { DataserviceService } from './../dataservice.service';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { HeatdetailComponent } from '../heatdetail/heatdetail.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatButtonModule, MatIconButton } from '@angular/material/button';
import { DateAdapter, MatNativeDateModule, MatOption, provideNativeDateAdapter } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatHint, MatSuffix } from '@angular/material/form-field';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { Location } from '@angular/common';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { MatButtonToggle, MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss'],
    standalone: true,
    providers: [provideNativeDateAdapter()],
    imports: [MatButtonToggleModule, ProgressspinnerComponent, MatExpansionModule, MatNativeDateModule, NgIf, BreadcrumbnavComponent, MatFormField, MatLabel, MatInput, MatDatepickerInput, MatHint, MatDatepickerToggle, MatSuffix, FormsModule, MatDatepicker, MatSelect, NgFor, MatOption, MatButton, MatIconButton, MatIcon, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, NgClass, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator]
})
export class BillingComponent {

    currentBuildingBillInfo: IBuildingBillingInfo;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<IApartmentBillingInfo>;
    monts: number[] = [];
    montsStart: number[] = [];
    billmonth = 0;
    breadcrumb: IBreadcrumbData[] = [];
    progress = false;
    filters: [];

    // refValue = 0;
    currentBillingId = '';
    building: IBuilding;
    currentBilling: IBillingBuilding;
    constructor(private location: Location, private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.progress = true;
        this.route.paramMap.subscribe(async (map) => {
            // console.log('Pmap; ', map.get('id'));
            this.currentBillingId = map.get('idb');

            const currentId = parseInt(map.get('id'), 10);
            const st = parseInt(map.get('st'), 10);
            const en = parseInt(map.get('en'), 10);
            this.monts = this.getLastMonts();
            this.montsStart = this.getLastMontsStart(this.monts[0]);

            this.dataservice.getBillBuilding(this.currentBillingId, currentId, st, en).subscribe(
                (data) => {

                    console.log('xxxxxxxxxxxxxxxx');
                    this.currentBilling = data as IBillingBuilding;
                    this.currentBuildingBillInfo = this.currentBilling.billing;
                    this.location.go('/billing/' + this.currentBilling.billingId + '/' + this.currentBilling.buildingId);

                    this.currentBuildingBillInfo.timestamp = new Date(this.currentBuildingBillInfo.timestamp);
                    this.dataservice.getFullBuilding(this.currentBuildingBillInfo.buildingId).subscribe((building) => {

                        this.building = building as IBuilding;
                        if (this.currentBuildingBillInfo.billComponents === undefined) {
                            this.currentBuildingBillInfo.billComponents = 0;
                        }
                        if (this.currentBuildingBillInfo.apartments) {
                            this.dataSource = new MatTableDataSource<IApartmentBillingInfo>(this.currentBuildingBillInfo.apartments);
                            this.breadcrumb.push({ label: 'HOME', url: '/' });
                            this.breadcrumb.push({ label: 'stabili', url: '/buildings' });
                            this.breadcrumb.push({ label: this.building.name, url: '/building/' + this.building.buildingId });
                            this.breadcrumb.push({ label: 'bollette', url: '/billings/' + this.building.buildingId });
                            this.breadcrumb.push({ label: new Date(this.currentBilling.timestamp).toLocaleString(), url: 'javascript:;' });
                        }
                        this.rebill();
                    });
                },
                () => {
                    this.progress = false;
                    this.dataservice.toastr.error('Si è verificato un errore');
                    this.currentBuildingBillInfo = undefined;
                }
            );
        });
    }

    selMedium(arg0: number) {

        const pos = this.currentBuildingBillInfo.filter.indexOf(arg0);
        if (pos !== -1) {
            if (this.currentBuildingBillInfo.filter.length > 1) {
                this.currentBuildingBillInfo.filter.splice(pos, 1);
            }
        } else {
            this.currentBuildingBillInfo.filter.push(arg0);
        }

        this.rebill();
    }

    selTotal(dev: IMeterDeviceBillingInfo, apa: IApartmentBillingInfo) {
        if (this.currentBilling.draft) {

            const dialogRef = this.dialog.open(BillcustomstatComponent, {
                data: { device: dev, apartment: apa.name },
                width: '800px',
                height: '600px'
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    console.log(result);
                    // dev.stats[index].value = this.dataservice.setMediumValue(dev.medium, result.value);
                    dev.dataCalcMode = result[1];
                    
                    dev.fakeData = this.dataservice.setMediumValue(dev.medium, result[0]);
                  

                    console.log('bill', this.currentBilling);
                    this.rebill();
                }
            });

        }
    }

    getBuildingWEBilling() {
        /*        this.dataservice.getCSVWEBillingBuilding(this.currentBuildingBillInfo).subscribe(data=>{
                    const a = document.createElement('a');
                    const txt = data as string;
                    const blob = new Blob([txt], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                
                    a.href = url;
                    a.download =this.currentBilling. this.currentBuildingBillInfo.title + "_" + (this.dataservice.monthNamesIt[this.selected.mm]) + '_' + this.selected.yy + ".csv";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                });*/
    }




    changeStatus() {
        this.rebill();
    }
    getLastMonts() {
        const ret = [];
        const now = new Date();
        const nowIndex = (now.getFullYear() - 2000) * 12 + now.getMonth();
        // console.log(nowIndex);
        // const month = now.getMonth();
        // ret.push(new Date());
        for (let i = 0; i < 12; i++) {
            ret.push(nowIndex - i);
        }
        //  this.refValue = ret[0];
        return ret;
    }

    getLastMontsStart(start: number) {
        const ret = [];
        const now = new Date();

        // console.log(nowIndex);
        // const month = now.getMonth();
        // ret.push(new Date());
        for (let i = 0; i < 12; i++) {
            ret.push(start - i);
        }
        //  this.refValue = ret[0];
        return ret;
    }

    selMonth(index: number) {
        console.log('xxxxxxxxxxxxx');
        this.billmonth = index;
        this.currentBilling.reference = index;
        this.currentBuildingBillInfo.reference = index;
        const year = (Math.trunc(index / 12) + 2000);

        this.currentBilling.billing.title = 'Stagione riscaldamento ' + (year - 1) + '/' + year;
        this.montsStart = this.getLastMontsStart(index - 1);
        this.currentBilling.referenceStart = index - 12;
        // this.reselect();
    }
    /*
        reselect() {
            const now = new Date();
            // const month = now.getMonth();
            const builldate = new Date();
            builldate.setMonth(this.billmonth % 12);
            builldate.setFullYear(Math.trunc(this.billmonth / 12) + 2000);
            for (const apa of this.currentBuildingBillInfo.apartments) {
                for (const dev of apa.meterDevices) {
                    const stat = dev.stats.find((x) => x.yy === builldate.getFullYear() && x.mm === builldate.getMonth());
                    if (stat) {
                        dev.index = dev.stats.indexOf(stat);
                    }
                }
            }
            this.rebill();
        }
    */
    rebill() {
        this.progress = true;
        this.dataservice.postCalcBillBuilding(this.currentBilling).subscribe((data) => {
            this.currentBilling = data as IBillingBuilding;
            this.currentBuildingBillInfo = this.currentBilling.billing;
            for (const apa of this.currentBuildingBillInfo.apartments) {
                apa.billable = true;
                apa.meterTotalValue = 0;

                for (const dev of apa.meterDevices) {
                    if (!dev.billable && !dev.deleted && !dev.hidden) {
                        apa.billable = false;
                    }
                }
            }
            this.progress = false;
        }, () => {
            this.progress = false;
            this.dataservice.toastr.error('Si è verificato un errore');


        });
    }

    getBillStartDate(date: number) {
        const month = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];

        return month[date % 12] + ' ' + (Math.trunc(date / 12) + 2000);
    }

    getStatDate(item: IMeterDeviceStatBillingInfo) {
        if (item.mm === -1) {
            return 'CUST';
        }
        const month = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];
        return month[item.mm] + ' ' + (item.yy - 2000);
    }

    getBillable(apa: IApartmentBillingInfo) {
        // return this.currentBuildingBillInfo.apartments.find((x) => x.apartmentId === apa.apartmentId).billable;
        for (const dev of apa.meterDevices) {
            if (!dev.billable && !dev.deleted && !dev.hidden) {
                return false;
            }
        }
        return true;
    }

    getBuildingBillable() {
        // return this.currentBuildingBillInfo.apartments.find((x) => x.apartmentId === apa.apartmentId).billable;
        for (const apa of this.currentBuildingBillInfo.apartments) {
            for (const dev of apa.meterDevices) {
                if (!dev.billable && !dev.deleted && !dev.hidden) {
                    return false;
                }
            }
        }
        return true;
    }

    getBillableManual(apa: IApartmentBillingInfo) {
        // return this.currentBuildingBillInfo.apartments.find((x) => x.apartmentId === apa.apartmentId).billable;
        let count = 0;
        for (const dev of apa.meterDevices) {
            if (dev.dataCalcMode > 0 && dev.dataCalcMode < 3 && !dev.deleted && !dev.hidden) {
                count++;
            }
        }
        if (count > 0) {
            return 'Forzati: ' + count;
        }
        return '';
    }

    getBillableDeleted(apa: IApartmentBillingInfo) {
        // return this.currentBuildingBillInfo.apartments.find((x) => x.apartmentId === apa.apartmentId).billable;
        let count = 0;
        for (const dev of apa.meterDevices) {
            if (dev.deleted) {
                count++;
            }
        }
        if (count > 0) {
            return 'Eliminati: ' + count;
        }
        return '';
    }

    getBillableNumber(apa: IApartmentBillingInfo) {
        // return this.currentBuildingBillInfo.apartments.find((x) => x.apartmentId === apa.apartmentId).billable;
        let count = 0;
        let total = 0;
        for (const dev of apa.meterDevices) {
            if (!dev.deleted && !dev.hidden) {
                total++;
                if (dev.billable) {
                    count++;
                }
            }
        }
        return count + ' di ' + total;
    }

    getTotal(apa: IApartmentBillingInfo) {
        return this.currentBuildingBillInfo.apartments.find((x) => x.apartmentId === apa.apartmentId).meterTotalValue;
    }

    getDeviceStats(apa: IApartmentBillingInfo, dev: IMeterDevice) {
        console.log(apa);
        const device = apa.meterDevices.find((x) => x.meterDeviceId === dev.meterDeviceId);
        return device.stats;
    }

    getBillDevice(apa: IApartmentBillingInfo, dev: IMeterDevice) {
        // const apartment = this.currentBuildingBillInfo.apartments.find((x) => x.apartmentId === apa.apartmentId);
        const device = apa.meterDevices.find((x) => x.meterDeviceId === dev.meterDeviceId);
        return device;
    }


    getBuildingBilling() {
        this.dataservice.postBuildingBillingReport(this.currentBilling.billingId, this.currentBilling.billing);
    }

    getColumns() {
        return ['position', 'pname', 'billable', 'devices'];
    }
    select(item: IApartmentBillingInfo) { }

    submit() {
        this.rebill();
    }

    getBill(element: IApartmentBillingInfo) {
        this.dataservice.getApartmentBilling(this.currentBuildingBillInfo, element.apartmentId);

    }

    getAllBill() {
        this.dataservice.getApartmentBilling(this.currentBuildingBillInfo, 0);
    }

    selectDevice(item) {
        this.dataservice.getMeterDetail(item.meterDeviceId).subscribe((data) => {
            const dialogRef = this.dialog.open(HeatdetailComponent, {
                data: data as IMeterDevice,
                width: '90vw',
                height: '90vh'
            });

            dialogRef.afterClosed().subscribe((result) => {
                /*  if (result) {
                    // console.log(result);
                    this.dataservice.saveMeter(result as IMeterDevice).subscribe((x) => {
                        this.dataservice.toastr.success('Salvataggio eseguito');
                    });
                }*/
                this.rebill();
            });
        });
    }



    getCost(item: number) {
        if (item !== undefined && !isNaN(item)) {
            return item.toFixed(2) + '€';
        }
        return 'N/A';
    }

    getErrorCost() {
        const totalCalc = this.currentBuildingBillInfo.voluntaryCost + this.currentBuildingBillInfo.involuntaryCost;
        const total = this.currentBuildingBillInfo.buildingHeatCost + this.currentBuildingBillInfo.buildingEnergyCost + this.currentBuildingBillInfo.buildingMantainCost + this.currentBuildingBillInfo.buildingManutCost;
        return (total - totalCalc).toFixed(2) + '€';
    }

    getValueStr(dev: IMeterDeviceBillingInfo, item: number) {
        if (dev === undefined || item === undefined) {
            return 'N/D';
        }
        if (item === -1) {
            return 'N/D';
        }
        return this.dataservice.getDecValue(dev, item);
    }

    recalcBilling() { }
}
